/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {Container, PageOffset} from 'components';
import {PageLayout as MDXLayout} from "components";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(Container, null, React.createElement(_components.p, null, "Hey there, ", React.createElement(_components.a, {
    href: "https://gannochenko.dev"
  }, "I am Sergei"), ". A few months ago I started learning Blender, and I must say, that Blender has one of the most sophisticated system of hotkeys I've ever seen."), React.createElement(_components.p, null, "So, I quickly realised that in order to be effective in Blender, one must learn it's hotkeys."), React.createElement(_components.p, null, "At fist, I was noting down various hotkeys I found useful while watching YouTube tutorials, but those records were meant to be for private use only. Then I realised: why don't I make it all public at some point?"), React.createElement(_components.p, null, "I saw a lot of good resources covering that topic, but since I am also an engineer, I've decided to do it my way :) So I really hope someone finds this website useful, as I am planning to invest some time into development."), React.createElement(_components.p, null, "If you have something to say, or you have a good hotkey to share, don't hesitate contacting me"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://gannochenko.dev/contacts"
  }, "through my website")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.instagram.com/im_a_creator_of_worlds"
  }, "via Instagram")), "\n"), React.createElement(_components.p, null, "Also keep an eye on my ", React.createElement(_components.a, {
    href: "https://www.youtube.com/@imacreatorofworlds"
  }, "Youtube channel"), "!"), React.createElement(_components.p, null, "See you online!")), "\n", React.createElement(PageOffset));
}
function MDXContent(props = {}) {
  return React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props));
}
export default MDXContent;
